import React from 'react';
import { useProfile } from './hooks';
import { withProfilePage } from 'hooks/with-profile-page';
import CsrTable from 'components/common/csr-table';

import InputEdit from 'components/common/input-edit';
import Section from 'components/common/section';
import { useTabs } from 'components/common/tabs/hooks';
import PoItems from './components/po-items';
import Metadata from 'components/common/metadata';
import { Divider } from 'antd';
import Delivery from './components/delivery';
import Payment from './components/payment';
import Contractor from './components/contractors';

function ItemProfilePage(pageProps) {
  const {
    data,
    history_logs,
    metadata,
    schema,
    setInputs,
    readonly,
    EntityLogs,
    columns,
  } = useProfile({ pageProps });

  const { isEditMode, mode } = pageProps;

  const { Component, tabProps } = useTabs();
  const { Tabs, Tab } = Component;

  const inputEditProps = {
    mode,
    schema,
    setInputs,
  };

  return (
    <div className='flex flex-col gap-4'>
      <Metadata metadata={metadata} />
      <Tabs {...tabProps}>
        <Tab title='Basic Information' id='info'>
          <Section>
            {isEditMode && (
              <>
                <InputEdit
                  {...inputEditProps}
                  field='po_number'
                  value={data?.po_number}
                />
                <Divider />
              </>
            )}
            <PoItems {...inputEditProps} data={data} readonly={readonly} />
            <Divider />
            <Delivery {...inputEditProps} data={data} readonly={readonly} />
            <Contractor {...inputEditProps} data={data} readonly={readonly} />
            {/* <Divider />
            <Payment {...inputEditProps} data={data} readonly={readonly} /> */}
          </Section>
        </Tab>
        {/* {isEditMode ? (
          <Tab title="Logs" id="logs">
            <Section title="Logs">{EntityLogs}</Section>
          </Tab>
        ) : null} */}
        {isEditMode ? (
          <Tab title='Email Logs' id='logs'>
            <Section title='Email Logs'>
              <CsrTable
                columns={columns}
                data={history_logs}
                size='small'
                pagination={{ pageSize: 50 }}
                scroll={{
                  x: 800,
                  y: 600,
                }}
                bordered
              />
            </Section>
          </Tab>
        ) : null}
      </Tabs>
    </div>
  );
}

export default withProfilePage(ItemProfilePage);
