import { useFetch } from 'lib/fetch';

// 10 -> 20
export const usePrSubmitGetQuote = params => {
  const { fetch, isLoading, result } = useFetch();
  const { _id } = params;

  const onSubmit = () => {
    if (_id) {
      fetch('POST', `/api/collection/pr/submit/get-quote/${_id}`);
    }
  };

  return {
    onSubmit,
    result,
    isLoading,
  };
};

// 20 -> 30
export const usePrSubmitQuoteDone = params => {
  const { fetch, isLoading, result } = useFetch();
  const { _id } = params;

  const onSubmit = () => {
    if (_id) {
      fetch('POST', `/api/collection/pr/submit/quote-done/${_id}`);
    }
  };

  return {
    onSubmit,
    result,
    isLoading,
  };
};

// 30 -> 20 (back)
export const usePrBacktoQuote = params => {
  const { fetch, isLoading, result } = useFetch();
  const { _id } = params;

  const onSubmit = () => {
    if (_id) {
      fetch('POST', `/api/collection/pr/submit/backto-quote/${_id}`);
    }
  };

  return {
    onSubmit,
    result,
    isLoading,
  };
};

// 30 -> 35
export const usePrSubmitQuoteVerified = params => {
  const { fetch, isLoading, result } = useFetch();
  const { _id } = params;

  const onSubmit = () => {
    if (_id) {
      fetch('POST', `/api/collection/pr/submit/quote-verified/${_id}`);
    }
  };

  return {
    onSubmit,
    result,
    isLoading,
  };
};

// 35 -> 40
export const usePrSubmitConfirmQuote = params => {
  const { fetch, isLoading, result } = useFetch();
  const { _id } = params;

  const onSubmit = () => {
    if (_id) {
      fetch('POST', `/api/collection/pr/submit/confirm-quote/${_id}`);
    }
  };

  return {
    onSubmit,
    result,
    isLoading,
  };
};

// 30 -> 40
export const usePrSubmitQuoteVerifiedConfirm = params => {
  const { fetch, isLoading, result } = useFetch();
  const { _id } = params;

  const onSubmit = () => {
    if (_id) {
      fetch('POST', `/api/collection/pr/submit/quote-verified-confirm/${_id}`);
    }
  };

  return {
    onSubmit,
    result,
    isLoading,
  };
};

// 60 -> 65
export const usePrSubmitSubcontractorVerified = params => {
  const { fetch, isLoading, result } = useFetch();
  const { _id } = params;

  const onSubmit = state => {
    if (_id) {
      fetch('POST', `/api/collection/pr/submit/subcontractor-verified/${_id}`, {
        params: { state },
      });
    }
  };

  return {
    onSubmit,
    result,
    isLoading,
  };
};

// amendment PR
export const usePrAmendment = params => {
  const { fetch, isLoading, result } = useFetch();
  const { _id } = params;

  const onSubmit = state => {
    if (_id) {
      fetch('POST', `/api/collection/pr/submit/amendment/${_id}`);
    }
  };

  return {
    onSubmit,
    result,
    isLoading,
  };
};

// supplement PR
export const usePrSupplement = params => {
  const { fetch, isLoading, result } = useFetch();
  const { _id } = params;

  const onSubmit = state => {
    if (_id) {
      fetch('POST', `/api/collection/pr/submit/supplement/${_id}`);
    }
  };

  return {
    onSubmit,
    result,
    isLoading,
  };
};
