import InputEdit from 'components/common/input-edit';
import { withPoItems } from './hooks';
import CsrTable from 'components/common/csr-table';
import { Checkbox, Divider, Input } from 'antd';
import { filter, find, map } from 'lodash';
import classNames from 'classnames';
import { isPriceFormat } from 'lib/price';
import { EditOutlined, WarningOutlined } from '@mui/icons-material';

import './table.css';

function PoItems(props) {
  const {
    body,
    columns,
    items,
    onSelectChange,
    getCheckboxProps,
    setHideOptions,
    hideOptions,
    setInputs,
    schema,
    value,
    selected,
    totalPrice,
    readonly,
    remarks,
    setRemarks,
  } = props;

  return (
    <div className='flex flex-col mb-4'>
      <CsrTable
        columns={columns}
        data={items}
        size='small'
        bordered
        scroll={{
          x: 800,
        }}
        pagination={
          readonly
            ? false
            : {
                pageSize: 20,
                position: ['botRight'],
                showSizeChanger: false,
              }
        }
        expandable={{
          columnWidth: 36,
          expandedRowKeys: readonly
            ? filter(selected.items, item => item?.remark).map(item => item._id)
            : undefined,
          expandedRowRender: record => {
            return readonly ? (
              <div className='px-4 py-2'>備註：{record.remark}</div>
            ) : (
              <div className='flex flex-col gap-2 px-4 py-2'>
                <Input
                  placeholder='備註'
                  size='small'
                  onChange={e =>
                    setRemarks(r => ({
                      ...r,
                      [record._id]: e.target.value,
                    }))
                  }
                />
              </div>
            );
          },
          rowExpandable: record => (readonly ? record?.remark : true),
          expandIcon: ({ expanded, onExpand, record }) => {
            const warning =
              !expanded &&
              remarks[record._id] &&
              !find(selected?.items, { _id: record._id });
            const iconProps = {
              style: { fontSize: 18, cursor: 'pointer', color: 'inherit' },
              onClick: e => onExpand(record, e),
            };
            return (
              <div
                className={classNames({
                  'text-slate-400': !expanded,
                  'text-blue-500': expanded,
                  'text-yellow-300': warning,
                  'hover:text-blue-500': !warning,
                })}
              >
                {warning ? (
                  <WarningOutlined {...iconProps} />
                ) : (
                  <EditOutlined {...iconProps} />
                )}
              </div>
            );
          },
          expandIconColumnIndex: readonly ? -1 : 1,
          fixed: true,
        }}
        rowSelection={
          readonly
            ? null
            : {
                type: 'checkbox',
                getCheckboxProps,
                onChange: onSelectChange,
                selectedRowKeys: map(selected.items, '_id'),
                fixed: true,
              }
        }
        footer={() =>
          readonly ? null : (
            <div className='flex gap-4 p-1'>
              {/* <Checkbox onChange={() => setHideOptions(!hideOptions)} checked={hideOptions}>
                隱藏無效選項
              </Checkbox> */}
            </div>
          )
        }
      />
      <Divider />
      <div className='flex flex-col gap-2 text-base'>
        <div className='w-full flex gap-4'>
          <div className='w-1/2'>
            <div className='text-sm mb-1 ml-1'>合約</div>
            <div
              className={classNames([
                'border px-3 rounded h-10 flex items-center border-zinc-300',
                { 'text-zinc-400': readonly },
              ])}
            >
              {selected.contract?.label}
            </div>
          </div>
          <div className='w-1/2'>
            <div className='text-sm mb-1 ml-1'>供應商</div>
            <div
              className={classNames([
                'border px-3 rounded h-10 flex items-center border-zinc-300',
                { 'text-zinc-400': readonly },
              ])}
            >
              {selected.supplier?.label}
            </div>
          </div>
        </div>
        <div className='w-full flex gap-4'>
          <div className='w-1/2'>
            <div className='text-sm mb-1 ml-1'>總價(HKD)</div>
            <div
              className={classNames([
                'border px-3 rounded h-10 flex items-center border-zinc-300',
                { 'text-zinc-400': readonly },
              ])}
            >
              {isPriceFormat(totalPrice)}
            </div>
          </div>
          <div className='w-1/2' />
        </div>
      </div>
    </div>
  );
}

export default withPoItems(PoItems);
