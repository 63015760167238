import { usePrSupplement } from 'hooks/use-pr';
import { redirect } from 'lib/location';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { user } from 'store';

export const withSupplementButton = WrappedComponent => {
  return props => {
    const { body, className, data } = props;
    const [open, setOpen] = useState(false);
    const {
      amendment_root,
      created_by,
      job_type,
      _id,
      supplement_root,
      revised_by,
    } = data || {};
    const { onSubmit, isLoading, result } = usePrSupplement({ _id: _id });

    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const currentUser = useAtomValue(user);
    const currentUserId = currentUser?._id;

    useEffect(() => {
      if (result && result?._id) {
        redirect(`/pr/profile/${result._id}`);
      }
    }, [result, data]);

    if (
      amendment_root ||
      supplement_root ||
      revised_by ||
      job_type !== 'm_and_m'
    ) {
      return null;
    }

    if (data && currentUserId === created_by?._id && isEmpty(body)) {
      return (
        <WrappedComponent
          {...props}
          open={open}
          onSubmit={onSubmit}
          handleDialogOpen={handleDialogOpen}
          handleDialogClose={handleDialogClose}
          submitted={isLoading || result}
        />
      );
    }
    return null;
  };
};
