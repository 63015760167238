import React from 'react';
// import { Divider } from '@mui/material';
import { Divider } from 'antd';
import InputEdit from 'components/common/input-edit';
import { withContractors } from './hooks';
import To from 'components/common/to';

function Contractors(props) {
  const { data, readonly, pr__subcontractors } = props;

  return (
    <>
      {pr__subcontractors &&
        pr__subcontractors.length > 0 &&
        pr__subcontractors?.map((d, d_index) => {

          return (
            <>
              {d?.subcon.length > 0 && (
                <>
                  <Divider />
                  <div className='flex w-full gap-4 mb-2'>
                    <To href={`/pr/profile/${d?.pr}`} toNew>
                      {d?.pr_code}
                    </To>
                  </div>
                  {d?.subcon?.map((s, s_index) => {
                    return (
                      <>
                        <div className='flex w-full gap-4'>
                          <InputEdit
                            schema={{
                              type: 'text',
                              readonly: true,
                              title: '判頭',
                            }}
                            field='subcontractor'
                            value={`[${s?.subcontractor?.code}] ${s?.subcontractor?.chin_name}`}
                            className='basis-2/3'
                            overrideSchema={{ readonly }}
                          />
                          <InputEdit
                            schema={{
                              type: 'text',
                              readonly: true,
                              title: '佔比',
                            }}
                            field='percentage'
                            value={s?.percentage}
                            className='basis-1/3'
                            overrideSchema={{ readonly }}
                          />
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </>
          );
        })}
    </>
  );
}

export default withContractors(Contractors);
