import React from 'react';
import { Divider } from '@mui/material';
import InputEdit from 'components/common/input-edit';
import { withDelivery } from './hooks';

function Delivery(props) {
  const { inputEditProps, address, name, phone, options, setRecord, data, setDepartment } = props;
  return (
    <>
      <div className="my-2">
        <Divider />
      </div>
      <div className="flex w-full gap-4">
        <InputEdit
          {...inputEditProps}
          field="delivery_department"
          value={data?.delivery_department}
          setInputs={setDepartment}
          className="basis-1/3"
          overrideSchema={{ selectOptions: options, input_type: 'select' }}
        />
        <div className="basis-1/3" />
        <div className="basis-1/3" />
      </div>

      <div className="flex w-full gap-4">
        <InputEdit
          {...inputEditProps}
          field="delivery_address"
          value={address}
          setInputs={setRecord}
          className="basis-1/3"
        />
        <InputEdit
          {...inputEditProps}
          field="delivery_contact_name"
          value={name}
          setInputs={setRecord}
          className="basis-1/3"
        />
        <InputEdit
          {...inputEditProps}
          field="delivery_contact_phone"
          value={phone}
          setInputs={setRecord}
          className="basis-1/3"
        />
      </div>
      <div className="flex w-full gap-4">
        <InputEdit
          {...inputEditProps}
          field="delivery_date"
          value={data?.delivery_date}
          setInputs={setRecord}
          className="basis-1/3"
        />
      </div>

      <div className="my-2">
        <Divider />
      </div>
    </>
  );
}

export default withDelivery(Delivery);
