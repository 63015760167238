import React from 'react';
import { Divider } from '@mui/material';
import InputEdit from 'components/common/input-edit';
import { withPayment } from './hooks';

function Payment(props) {
  const { schema, description, name, phone, options, setRecord, data, setPayment, readonly } = props;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full gap-4">
        <InputEdit
          schema={schema}
          field="payment_term_code"
          value={data?.payment_term_code}
          setInputs={setPayment}
          className="basis-1/3"
          overrideSchema={{ selectOptions: options, input_type: 'select', readonly }}
        />
        <div className="basis-1/3" />
        <div className="basis-1/3" />
      </div>
      <div className="flex w-full gap-4">
        <div className="w-1/2">
          <InputEdit
            schema={schema}
            field="payment_term_description"
            value={description}
            setInputs={setRecord}
            className="basis-1/2"
            overrideSchema={{ readonly }}
          />
        </div>
        <div className="w-1/2" />
      </div>
    </div>
  );
}

export default withPayment(Payment);
