import { useCollectionListing } from 'hooks/use-collection-listing';
import { find, isEmpty, map, reduce, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTable } from 'components/common/table/hooks';
import { useFetch } from 'lib/fetch';
import { useLocation, useParams } from 'react-router-dom';

export const withContractors = WrappedComponent => {
  return props => {
    const { data } = props;
    const { poData } = useFetchPoData(data?._id);
    const [record, setRecord] = useState({});

    const uniquePRid = [];
    const uniquePR = [];
    if (poData && poData?.pr__subcontractors) {
      for (let i = 0; i < poData?.pr__subcontractors.length; i++) {
        const item = poData?.pr__subcontractors[i];

        if (!uniquePRid.includes(item.pr)) {
          item.pr_code = poData?.pr_number[i];
          uniquePRid.push(item.pr);
          uniquePR.push(item);
        }
      }
    }

    return (
      <WrappedComponent
        {...props}
        setRecord={setRecord}
        record={record}
        pr__subcontractors={uniquePR}
      />
    );
  };
};

export const useFetchPoData = data_id => {
  let { _id } = useParams();
  if (!_id && data_id) {
    _id = data_id;
  }

  const { fetch, result } = useFetch();
  useEffect(() => {
    fetch('GET', `/api/collection/po/print-form/${_id}`);
  }, [_id]);

  return {
    _id: _id,
    poData: result?.data,
  };
};
