import React from 'react';
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { Add } from '@mui/icons-material';
import { withSupplementButton } from './hooks';

function SupplementButton(props) {
  const {
    className,
    open,
    onSubmit,
    handleDialogClose,
    handleDialogOpen,
    submitted,
  } = props;
  return (
    <div className={`${className} flex justify-end items-center w-full`}>
      <Button
        variant='outlined'
        color='info'
        endIcon={<Add />}
        onClick={handleDialogOpen}
      >
        追加申請
      </Button>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        sx={{
          '.MuiDialog-paper': {
            width: 500,
          },
        }}
      >
        <DialogTitle>你確定要追加申請嗎?</DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>取消</Button>
          <Button
            onClick={onSubmit}
            variant='contained'
            autoFocus
            disabled={submitted}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withSupplementButton(SupplementButton);
