import React, { useEffect } from 'react';
import { useDataEdit } from 'hooks/use-data-edit';
import { useTopNav } from 'components/common/top-nav/hooks';
import { redirect } from 'lib/location';
import { useEntityLogs } from 'components/common/entity-logs/hooks';
import { useFetch } from 'lib/fetch';
import To from 'components/common/to';
import dayjs from 'dayjs';
import SendEmailButton from './components/sendemail-button';
import { Button } from '@mui/material';
import { Print } from '@mui/icons-material';

export const useProfile = ({ pageProps }) => {
  const { _id, mode, collection, metadata, isLoading, isEditMode, isCopyMode } = pageProps;
  const url = isEditMode
    ? `/api/collection/${collection}/edit/${_id}`
    : isCopyMode
    ? `/api/collection/${collection}/copy/${_id}`
    : `/api/collection/${collection}/new`;
  const { Component: EntityLogs, refetch: refetchLogs } = useEntityLogs({ _id });
  const { setTopNav } = useTopNav();
  const { data, config, controller, save, canSave } = useDataEdit({
    mode,
    value: pageProps,
    config: { editable: false, ...pageProps },
    url,
    refetch: () => {
      pageProps?.fetchPageData();
      refetchLogs();
    },
    success:
      mode === 'new' || mode === 'copy'
        ? (result) => {
            if (result && result?._id) {
              redirect(`/${collection}/profile/${result._id}`);
            }
          }
        : undefined
  });

  const { schema, fieldsToDisplay = [], isEdit } = config;
  const { setInputs } = controller;

  useEffect(() => {
    const copy = isEdit
      ? () => {
          redirect(`/${collection}/copy/${_id}`);
        }
      : null;
    const title = `${config?.title} ${data?.po_number ? '(' + data?.po_number + ')' : ''} `;
    const element = isEdit ? (
      <div className="flex flex-wrap gap-1">
        <div>{/* <SendEmailButton data={data} /> */}</div>
        <div>
          <Button variant="contained" color="info" endIcon={<Print />}>
            <To href={`/po/print-form/${data?._id}`} toNew>
              PDF
            </To>
          </Button>
        </div>
      </div>
    ) : null;
    //
    setTopNav({ save, canSave, title, element });
  }, [save, canSave, isEdit, _id, data, config]);

  useEffect(() => {
    setTopNav({ save, canSave });
  }, [save, canSave]);

  const readonly = isEditMode;

  const { history_logs } = useFetchEmailLog({ _id });
  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (_, item) => (
        <To href={`/user/profile/${item?.created_by}`} target="_blank">
          {item?.username}
        </To>
      )
    },
    {
      title: 'Sent at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, item) => <div>{dayjs(item?.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
    }
  ];

  return {
    _id,
    data,
    config,
    controller,
    save,
    canSave,
    metadata,
    schema,
    fieldsToDisplay,
    setInputs,
    isLoading,
    readonly,
    EntityLogs,
    columns,
    history_logs
  };
};

export const useFetchEmailLog = ({ _id }) => {
  const { fetch, result } = useFetch();

  useEffect(() => {
    if (_id) {
      fetch('GET', `/api/collection/email-log/logs-by-po/${_id}`);
    }
  }, [_id]);

  return {
    history_logs: result
  };
};
